import React from 'react'
import classnames from 'classnames'

import styles from './Info.module.scss'

const Info = () => (
  <div className={classnames([styles.info], 'wrapper-l')}>
    <p>&copy; {new Date().getFullYear()} - MOSS Consultants &amp; Capital</p>
  </div>
)

export default Info

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import imgOpengraph from '@assets/opengraph.jpg'

const Seo = ({
  description,
  lang,
  meta,
  ogImageFile,
  ogImageHeight,
  ogImageWidth,
  title,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            baseUrl
          }
        }
      }
    `
  )

  const metaTitle = title
    ? `${title} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title
  const metaDescription = description || data.site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: data.site.siteMetadata.baseUrl + ogImageFile,
        },
        {
          property: 'og:image:height',
          content: ogImageHeight,
        },
        {
          property: 'og:image:width',
          content: ogImageWidth,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ogImageFile: PropTypes.string,
  ogImageHeight: PropTypes.string,
  ogImageWidth: PropTypes.string,
  title: PropTypes.string,
}

Seo.defaultProps = {
  description: '',
  lang: 'en',
  meta: [],
  ogImageFile: imgOpengraph,
  ogImageHeight: '1200',
  ogImageWidth: '1200',
}

export default Seo

import React from 'react'

import Cta from './Cta'
import Info from './Info'
import Menus from './Menus'

const Footer = () => (
  <footer>
    <Cta />
    <Menus />
    <Info />
  </footer>
)

export default Footer

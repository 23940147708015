import IconSocialFacebook from '@assets/icons/social-facebook.inline.svg'
import IconSocialLinkedIn from '@assets/icons/social-linkedin.inline.svg'

const social = [
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/mosscc.org/',
    icon: IconSocialFacebook,
  },
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/moss-cc/',
    icon: IconSocialLinkedIn,
  },
]

export default social

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Intro.module.scss'

const Intro = ({ children, pageTitle, sectionId, sectionTitle, wrapper }) => (
  <div
    id={sectionId && `#${sectionId}`}
    className={classnames([styles.intro], [wrapper])}
  >
    {pageTitle && <h1 className="t-heading-xl">{pageTitle}</h1>}
    {sectionTitle && <h2 className="t-heading-l">{sectionTitle}</h2>}

    {children}
  </div>
)

Intro.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  sectionId: PropTypes.string,
  sectionTitle: PropTypes.string,
  wrapper: PropTypes.string,
}

Intro.defaultProps = {
  wrapper: 'wrapper-l'
}

export default Intro

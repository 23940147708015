import React from 'react'
import PropTypes from 'prop-types'

import Footer from '@components/structure/Footer'
import Navigation from '@components/structure/Navigation'
import Seo from '@components/utils/Seo'

import '../styles/style.scss'

const Root = ({ children }) => (
  <>
    <Seo />

    <Navigation />
    <main>{children}</main>
    <Footer />
  </>
)

Root.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Root

import React from 'react'
import { Link } from 'gatsby'

import styles from './Cta.module.scss'

const Cta = () => (
  <div className="wrapper-l">
    <div className={styles.cta}>
      <div className={styles.copy}>Act now. #changethesystem</div>

      <div className={styles.action}>
        <Link className={styles.button} to="/get-involved">
          Get Involved
        </Link>
      </div>
    </div>
  </div>
)

export default Cta

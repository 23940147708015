import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'

import Logomark from '@assets/logomark.inline.svg'
import social from '@assets/social'
import styles from './Menus.module.scss'

const Menus = () => {
  const links = {
    organization: [
      { title: 'About Us', path: '/about-us' },
      { title: 'Our Team', path: '/about-us#team' },
      { title: 'Volunteer', path: '/get-involved#volunteer' },
      { title: 'Vacancies', path: '/get-involved#vacancies' },
    ],
    getInvolved: [
      { title: 'Our Work', path: '/our-work' },
      { title: 'Workshops', path: '/our-work#workshops' },
      { title: 'Mentoring', path: '/our-work#mentoring' },
      { title: 'Trainings', path: '/our-work#trainings' },
    ],
    furtherInfo: [
      { title: 'Contact', path: '/contact' },
      { title: 'Terms & Conditions', path: '/terms-and-conditions' },
      { title: 'Privacy Policy', path: '/privacy-policy' },
    ],
  }

  return (
    <div className={classnames([styles.menus], 'wrapper-l')}>
      <div className={styles.address}>
        <div className={styles.address__logo}>
          <Logomark />
        </div>

        <div className={styles.address__copy}>
          <h3 className={styles.address__title}>
            MOSS Consultants &amp; Capital
          </h3>
          Larikslaan 13
          <br />
          5671 BN Nuenen
          <br />
          The Netherlands
        </div>
      </div>

      <div className={styles.links}>
        <h3 className={styles.menus__title}>Organization</h3>

        {links.organization.map(({ title, path }) => (
          <Link className={styles.links__item} key={path} to={path}>
            {title}
          </Link>
        ))}
      </div>

      <div className={styles.links}>
        <h3 className={styles.menus__title}>Get Involved</h3>

        {links.getInvolved.map(({ title, path }) => (
          <Link className={styles.links__item} key={path} to={path}>
            {title}
          </Link>
        ))}
      </div>

      <div className={styles.links}>
        <h3 className={styles.menus__title}>Further Info</h3>

        {links.furtherInfo.map(({ title, path }) => (
          <Link className={styles.links__item} key={path} to={path}>
            {title}
          </Link>
        ))}
      </div>

      <div className={styles.social}>
        <h3 className={styles.menus__title}>Follow Us</h3>

        {social.map((entry) => (
          <a
            className={styles.social__item}
            href={entry.url}
            key={entry.url}
            rel="noopener noreferrer"
            title={entry.title}
            target="_blank"
          >
            {<entry.icon />}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Menus

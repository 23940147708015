import React, { useState } from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'

import IconClose from '@assets/icons/close.inline.svg'
import IconMenu from '@assets/icons/menu.inline.svg'
import Logo from '@assets/logo.inline.svg'
import styles from './Navigation.module.scss'

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const menu = [
    { title: 'Home', path: '/' },
    { title: 'About Us', path: '/about-us' },
    { title: 'Our Work', path: '/our-work' },
    { title: 'Contact', path: '/contact' },
  ]

  return (
    <div
      // prettier-ignore
      className={classnames(
        ['wrapper-l'],
        [styles.navigation],
        { [styles.menuOpen]: menuOpen }
      )}
    >
      <div className={styles.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <button
        className={styles.menuToggle}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? <IconClose /> : <IconMenu />}
      </button>

      <nav className={styles.menu}>
        {menu.map(({ title, path }) => (
          <Link
            key={path}
            to={path}
            className={styles.link}
            activeClassName={styles.linkActive}
          >
            {title}
          </Link>
        ))}

        <Link
          to="/get-involved"
          className={styles.ctaLink}
          activeClassName={styles.ctaLinkActive}
        >
          Get Involved
        </Link>
      </nav>
    </div>
  )
}

export default Navigation
